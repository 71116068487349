<template>
  <BaseModal
    name="ModalPipelineEditar"
    id="ModalPipelineEditar"
    idModal="ModalPipelineEditar"
    size="xl"
    title="Atualizar CRM"
    @shown="fetchDataPipeline()"
    @hidden="hideDataPipeline()"
  >
    <div v-if="tags_props">
      <div class="flex-titulo">
        <div :style="isIframe ? 'width: 50%' : 'width:40%'">
          <h4 class="nome">Nome do CRM</h4>
          <input
            style="width: 100%"
            type="text"
            placeholder="Nome do meu CRM"
            v-model="titulo"
          />

          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 17px;
              margin-top: 13px;
            "
          >
            <span class="titleError text-danger" v-show="titleRequired"
              >Por favor, insira o nome do CRM</span
            >
            <span class="titleError text-danger" v-if="titleSpace"
              >Espaços inválidos</span
            >
            <span
              class="titleError text-danger"
              v-show="titleLength"
              v-if="titulo.length < 6 || titulo.length > 40"
              >Mínimo de 6 caracteres e máximo de 40 caracteres</span
            >
          </div>
        </div>
        <div style="margin-top: -27px" :style="isIframe ? 'width: 50%' : 'width:40%'">
          <div class="add-link-body">
            <div
              style="
                display: flex;
                gap: 8px;
                align-items: center;
                margin-bottom: 8px;
              "
            >
              <label class="nome mb-0" for="tag_exit">Tag saída</label>
              <img
                width="17px"
                height="17px"
                src="@/assets/img/icons/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover.top
                title="Lead irá receber uma tag ao ser removido do CRM"
              />
            </div>
            <router-link
              v-if="!tags_props.length"
              :to="{ name: 'Tags' }"
              class="add-link link mb-0"
              >Deseja criar uma tag?
            </router-link>
          </div>
          <multiselect
            v-if="tag_saida"
            id="tag_exit"
            label="name"
            v-model="tag_saida"
            :options="
              tags_props.filter((tag) =>
                selected_form.every((form) => form.id != tag.id)
              )
            "
            track-by="id"
            placeholder="Selecione uma tag"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :allow-empty="false"
            :searchable="true"
            :hideSelected="true"
          >
            <span slot="noResult">Oops! Nenhuma tag encontrada.</span>
          </multiselect>
        </div>
        <div style="width: 20%;" v-if="!isIframe">
          <BaseButton
            variant="link-info"
            @click="openModal('ModalAddTagPipeline')"
          >
            Criar tag
          </BaseButton>
        </div>
      </div>
      <div class="grid">
        <div
          class="boards"
          v-for="(item, index) in selected_form"
          :key="index"
          :class="`${item.color ? item.color : 'default'}_color`"
        >
          <div style="display: flex; justify-content: space-between">
            <label
              class="nome"
              style="color: white; font-weight: 500 !important"
              :for="`board${index}`"
              >Board 0{{ index + 1 }}</label
            >
            <div>
              <div class="image-control">
                <img
                  :class="{ 'disabled-arrow': index == 0 }"
                  class="first-arrow"
                  src="@/assets/editor/chevron.svg"
                  @click="index == 0 ? '' : moveUp(index)"
                />
                <button
                  @click="
                    selected_form.length == 1 ? '' : diminuirContador(index)
                  "
                >
                  <img
                    class="icon"
                    src="@/assets/icons/calendar/trash.svg"
                    alt="trash"
                    :style="
                      selected_form.length == 1
                        ? 'filter: invert(50%);'
                        : 'filter: brightness(2);'
                    "
                  />
                </button>
                <img
                  :class="{
                    'disabled-arrow': index + 1 == selected_form.length,
                  }"
                  class="last-arrow"
                  src="@/assets/editor/chevron.svg"
                  @click="
                    index + 1 == selected_form.length ? '' : moveDown(index)
                  "
                />
              </div>
            </div>
          </div>
          <multiselect
            v-if="tag_saida"
            :id="`board${index}`"
            label="name"
            v-model="selected_form[index]"
            :options="
              tags_props.filter(
                (tag) =>
                  selected_form.every((form) => form.id != tag.id) &&
                  tag_saida.id != tag.id
              )
            "
            track-by="id"
            placeholder="Selecione"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :allow-empty="false"
            :searchable="true"
            :hideSelected="true"
          >
            <span slot="noResult">Nenhuma tag encontrada.</span>
          </multiselect>
        </div>
        <div
          class="boards"
          v-if="selected_form.length <= 7"
        >
          <label
            class="nome"
            :style="
              selected_form.every((item) => item.hasOwnProperty('id'))
                ? ''
                : 'color: #dadada;'
            "
            :for="`board`"
            >Adicionar board</label
          >
          <div
            class="add-board"
            :style="
              selected_form.every((item) => item.hasOwnProperty('id'))
                ? ''
                : 'border-color: #dadada; color: #dadada; background-color: #efefef;'
            "
            @click="
              selected_form.every((item) => item.hasOwnProperty('id'))
                ? selected_form.push('null')
                : ''
            "
          >
            +
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-2" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="danger" class="mr-2" @click="deletePipeline">
        Deletar CRM
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar alterações
      </BaseButton>
    </template>
    
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <ModalAddTagPipeline
      v-if="!isIframe"
      :tagsProps="tags_props"
    />
  </BaseModal>
</template>

<script>
import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import Vue from "vue";

import Multiselect from "vue-multiselect";

export default {
  props: ["selectedPipeline", "tags_props", "isIframe"],
  components: {
    Multiselect,
    ModalAddTagPipeline: () => import('@/components/Pipeline/ModalAddTagPipeline')
  },
  data() {
    return {
      titleRequired: false,
      titleLength: false,
      titleSpace: false,
      titulo: "",
      tag: [],
      loading: false,
      selected_form: [],
      stops: [],
      tag_saida: undefined,
    };
  },
  methods: {
    openModal(data) {
      this.$bvModal.show(data);
    },
    diminuirContador(index) {
      this.selected_form.splice(index, 1);
    },
    moveUp(idx) {
      var moving = this.selected_form[idx];
      this.selected_form.splice(idx, 1);
      this.selected_form.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.selected_form[idx];
      this.selected_form.splice(idx, 1);
      this.selected_form.splice(idx + 1, 0, moving);
    },
    deletePipeline() {
      var data = {
        id: this.selectedPipeline.id,
      };
      Vue.swal({
        title: "CRM",
        text: `Deseja realmente deletar este CRM?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          servicePipeline.destroy(`delete/${data.id}`).then((resp) => {
            this.$grToast.toast(`CRM deletado`, {
              title: "CRM",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$bvModal.hide("ModalPipelineEditar");
            // this.$emit("reload-list");
            this.$emit("prev_selectedPipeline_localStorage");
          });
        }
      });
    },
    fetchDataPipeline() {
      this.titulo = this.selectedPipeline.title;
      if (this.selectedPipeline.tag_exit && this.selectedPipeline.tag_exit > 0) {
        let tag_saida = this.selectedPipeline.tag_exit;
        this.tag_saida = this.tags_props.find(
          (tagFind) => tagFind.id == tag_saida
        );
      } else {
        this.tag_saida = {
          name: "Sem tag de saída",
          id: 0,
        }
      }
      let id_tags = this.selectedPipeline.tags;
      let array_tags = [];
      let pipeline = this.selectedPipeline.id;
      for (let i = 0; i < id_tags.length; i++) {
        const tag_id = id_tags[i];
        let tag = this.tags_props.find((tagFind) => tagFind.id == tag_id);
        if (tag && pipeline && pipeline.tag_exit != tag_id) {
          array_tags.push(tag);
        }
      }
      if (this.tag_saida.id) {
        array_tags.pop();
      }
      this.selected_form = array_tags;
    },
    hideDataPipeline() {
      this.titulo = "";
      this.tag_saida = "";
      this.selected_form = [];
    },
    onSubmit() {
      var new_selected_form = [];
      var result_tags;
      var pipeline = this.selectedPipeline;
      for (let i = 0; i < this.selected_form.length; i++) {
        const element = this.selected_form[i];
        new_selected_form.push(element.id);
      }

      new_selected_form.map(String);
      var valueArr = new_selected_form.map(function (item) {
        return item;
      });

      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) != idx;
      });

      var tag_saida_is_duplicate = this.selected_form.findIndex(
        (x) => x == this.tag_saida
      );

      if (tag_saida_is_duplicate !== -1) {
        new_selected_form.splice(tag_saida_is_duplicate, 1, pipeline.tag_exit);
      } else {
        new_selected_form.splice(new_selected_form.length, 1);
      }

      if (!this.tag_saida.id) {
        this.$grToast.toast("Selecione uma tag de saída", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      if (
        this.tag_saida &&
        this.tag_saida.id &&
        new_selected_form.findIndex((x) => x == this.tag_saida.id) != -1
      ) {
        this.$grToast.toast("A tag já está sendo utilizada como tag de saída", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      const regex = /  /;
      if (!this.titulo) {
        this.titleRequired = true;
        this.titleLength = false;
        this.loading = false;
        return;
      } else if (
        this.titulo.length < 6 ||
        this.titulo.length > 40 ||
        regex.test(this.titulo)
      ) {
        this.titleLength = true;
        this.titleRequired = false;
        this.loading = false;
        return;
      } else {
        this.titleRequired = false;
        this.titleLength = false;
      }

      if (isDuplicate === true) {
        this.$grToast.toast(
          "Existem campos selecionados com os mesmos boards",
          {
            title: "CRM",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        this.loading = false;
        return;
      } else {
        this.loading = true;
        this.$validator.validateAll().then((result) => {
          if (result) {
            if (
              this.selected_form.includes("null") ||
              this.selected_form.includes(null) ||
              this.selected_form.includes("null")
            ) {
              this.$grToast.toast("Obrigatório selecionar os campos de board", {
                title: "CRM",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.loading = false;
              return;
            }
            var tags = Array.from(new_selected_form);
            var result_tags = [];
            for (let i = 0; i < tags.length; i++) {
              const element = tags[i];
              if (i <= this.selected_form.length - 1 && element != null) {
                result_tags.push(element + "");
              }
            }
            if (this.tag_saida && this.tag_saida.id) {
              result_tags.push(this.tag_saida.id.toString());
            }

            var data = {
              id: `edit/${this.selectedPipeline.id}`,
              tags: result_tags,
              tag_exit: this.tag_saida.id,
              title: `${this.titulo}`,
            };
            servicePipeline
              .update(data)
              .then(() => {
                this.$emit("update", result_tags);
                this.$grToast.toast("CRM alterado com sucesso", {
                  title: "CRM",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.loading = false;
                this.$bvModal.hide("ModalPipelineEditar");
              })
              .catch((err) => {
                this.$grToast.toast("Erro ao alterar CRM", {
                  title: "CRM",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            if (this.titulo === "") {
              this.$grToast.toast("Obrigatório preencher o nome do CRM", {
                title: "CRM",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.loading = false;
              return;
            }
            if (this.selected_form.length === 0) {
              this.$grToast.toast("Obrigatório selecionar os campos de board", {
                title: "CRM",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.loading = false;
              return;
            }
            if (this.tag_saida === undefined) {
              this.$grToast.toast("Obrigatório selecionar a tag de saída", {
                title: "CRM",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.loading = false;
              return;
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.boards {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s;
}
.image-control {
  height: auto;
  display: flex;
  width: 35px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  button {
    height: 24px;
    width: 24px;
    border-radius: 10px;
    transition: all 0.3s;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 25px;
    cursor: pointer;
    img {
      height: 15px;
    }
  }
  div {
    width: min-content;
  }
  .first-arrow {
    rotate: 90deg;
    height: 15px;
    filter: brightness(2);
  }
  .last-arrow {
    rotate: -90deg;
    height: 18px;
  }
  img {
    cursor: pointer;
  }
  .disabled-arrow {
    filter: invert(50%);
    cursor: default !important;
  }
}
.add-board {
  height: 50px;
  border-radius: 10px;
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--greenn);
  color: var(--greenn);
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: #05840a22;
  }
}
.img {
  cursor: pointer;
}
.nome {
  font-weight: 600;
  font-size: 14px;
}
.flex-titulo {
  display: flex;
  align-items: center;
  gap: 10px;
  .titleError {
    font-size: 12px;
    margin-top: -20px;
    font-weight: 500;
  }
}
.flex {
  display: flex;
  align-items: center;
  gap: 25px;
  position: fixed;
  right: 50px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.b-flex {
  margin-top: 27px;
  display: flex;
  gap: 6px;
}
.info_color {
  background: var(--bluee);
}
.danger_color {
  background: var(--red);
}
.warning_color {
  background: var(--orange);
}
.primary_color {
  background: var(--greenn-light);
}
.success_color {
  background: #2ed7ee;
}
.default_color {
  background: var(--gray01);
}
</style>
