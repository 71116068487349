<template>
  <CRM v-if="hasLoaded" 
  ref="iframeCRM"
  :userData="userData" 
  :usersData="usersData" 
  :tagsData="tagsData"
  :recursosData="recursosData"
  :productsData="productsData" 
  :userLevelData="userLevelData"
  @returnPostMensage="returnPostMensage"
  ></CRM>
</template>

<script>
import CRM from "@/components/Pipeline/Pipeline.vue";
import iframeMixin from "@/mixins/iframeMixin";
export default {
  mixins: [iframeMixin],
  components: {
    CRM,
  },
  methods: {
    updateDataIframe(dataKey, newValue){
      this.$refs.iframeCRM.updateDataIframeCrm(dataKey, newValue)
    }
  },
}
</script>