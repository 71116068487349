var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{attrs:{"name":"ModalPipelineTagAdicionar","id":"ModalPipelineTagAdicionar","idModal":"ModalPipelineTagAdicionar","size":"xl","title":"Adicionar board"},on:{"shown":function($event){return _vm.isOpen()}},scopedSlots:_vm._u([{key:"footer",fn:function({ cancel }){return [_c('BaseButton',{staticClass:"mr-2",attrs:{"variant":"link-info"},on:{"click":cancel}},[_vm._v(" Fechar ")]),_c('BaseButton',{attrs:{"variant":"primary","disabled":_vm.loading || _vm.selectedId == null},on:{"click":_vm.onSubmit}},[_vm._v(" Adicionar ")])]}}])},[_c('div',{staticStyle:{"display":"grid","grid-template-columns":"1fr 290px","gap":"15px"}},[_c('div',{staticClass:"input-tag"},[_c('img',{staticClass:"Pointer searchIcon",attrs:{"src":require("@/assets/img/icons/search.svg")}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tagSearch),expression:"tagSearch"}],attrs:{"type":"text","placeholder":"Pesquise por uma tag"},domProps:{"value":(_vm.tagSearch)},on:{"input":function($event){if($event.target.composing)return;_vm.tagSearch=$event.target.value}}})]),_c('div',{staticClass:"grid-colors"},[_c('div',{staticClass:"primary",class:_vm.color_selected == 'primary' ? 'selected-color' : 'unselected-color',on:{"click":function($event){_vm.color_selected == 'primary'
            ? (_vm.color_selected = '')
            : (_vm.color_selected = 'primary'),
            _vm.filterSearchTagsUpdate()}}}),_c('div',{staticClass:"info",class:_vm.color_selected == 'info' ? 'selected-color' : 'unselected-color',on:{"click":function($event){_vm.color_selected == 'info'
            ? (_vm.color_selected = '')
            : (_vm.color_selected = 'info'),
            _vm.filterSearchTagsUpdate()}}}),_c('div',{staticClass:"danger",class:_vm.color_selected == 'danger' ? 'selected-color' : 'unselected-color',on:{"click":function($event){_vm.color_selected == 'danger'
            ? (_vm.color_selected = '')
            : (_vm.color_selected = 'danger'),
            _vm.filterSearchTagsUpdate()}}}),_c('div',{staticClass:"warning",class:_vm.color_selected == 'warning' ? 'selected-color' : 'unselected-color',on:{"click":function($event){_vm.color_selected == 'warning'
            ? (_vm.color_selected = '')
            : (_vm.color_selected = 'warning'),
            _vm.filterSearchTagsUpdate()}}}),_c('div',{staticClass:"default",class:_vm.color_selected == 'default' ? 'selected-color' : 'unselected-color',on:{"click":function($event){_vm.color_selected == 'default'
            ? (_vm.color_selected = '')
            : (_vm.color_selected = 'default'),
            _vm.filterSearchTagsUpdate()}}})])]),_c('div',{staticClass:"limit"},[_c('div',{staticClass:"grid"},_vm._l((_vm.filterSearchTags),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"single-tag",class:`${item.color} ${
            item.id == _vm.selectedId ? 'selected-tag' : ''
          } ${
            (_vm.getSelectedPipeline.includes(item.id.toString()) &&
              item.id != _vm.selectedId) ||
            _vm.tag_saida == item.id
              ? 'disabled-tag'
              : ''
          }`,on:{"click":function($event){_vm.getSelectedPipeline.includes(item.id.toString()) ||
            _vm.tag_saida == item.id
              ? ''
              : ((_vm.selectedId = item.id), (_vm.selectedTagID = item.id))}}},[_c('h4',{staticClass:"nome"},[_vm._v(" "+_vm._s(item.name.length > 22 ? item.name.substr(0, 22) + "..." : item.name)+" ")]),_c('div',{staticClass:"dot"})])])}),0)]),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }