<template>
  <div @click.prevent="selectLead(index)" class="list-group-item"  
  :data-lead="lead.id"
  :data-column-lead="item"
  :class="{ selected: isSelected }"
  >
    <div :style="inStyle"
      class="check-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" fill="#009488" viewBox="0 0 256 256">
        <path
          d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z">
        </path>
      </svg>
    </div>
    <div class="container-add" v-if="index >= 1">
      <div class="add-lead" @click="openModal('ModalAdicionarLead')">
        <img src="@/assets/icons/add2.svg" alt="add-icon" />
      </div>
    </div>
    <div v-if="lead">
      <div class="container-flex" :data-id="index">
        <div class="lead-photo" v-if="photoLead">
          <img 
            :src="getLeadphoto"
            alt="leadImage"
          />
        </div>
        <div class="lead-no-photo" v-else>
          <h3>
            {{ getMetaInitialLead }}
          </h3>
        </div>
        <div class="name-visualizer">
          <p class="nome">
            {{ lead.name ? lead.name : "Sem Nome" }}
          </p>
          <div @click.self="openModalIdLead" class="item-flex">
            <img @click.self="openModalIdLead" src="@/assets/icons/eye_green.svg"
              alt="icon" />
            <p @click.self="openModalIdLead" class="visualizar-lead">
              Visualizar
              <a v-if="!isIframe" @click="openLead()"> - nova aba </a>
            </p>
          </div>
        </div>
        <div class="select-seller">
          <div v-if="isSellerInvalid"
            @click.stop="openModalCardSeller()" 
            class="seller-no-ft3">
            <h3 v-b-tooltip.hover :title="filterTooltipVendedor">
              {{ filterSellerNoImg }}
            </h3>
          </div>

          <div v-if="isSellerValid" 
          class="seller-no-ft3">
            <h3 v-b-tooltip.hover :title="filterTooltipVendedor">
              {{ filterSellerNoImg }}
            </h3>
          </div>

          <img v-if="sellerNoIdent" 
            @click="openModalCardSeller()"
            class="user-icon" src="@/assets/icons/no-seller.svg" alt="no-seller" 
            v-b-tooltip.hover
            :title="filterTooltipVendedor" />
          <img v-if="sellerConfig && sellerIdent" 
            class="user-icon" src="@/assets/icons/no-seller.svg" alt="no-seller" 
            v-b-tooltip.hover
            :title="filterTooltipVendedor" />

          <div v-if="filterSellerImg != 'no-photo'">
            <img @click.stop="openModalCardSeller()" 
              class="user-icon2" v-if=" filterSellerImg && sellerNoIdent" 
              :src="filterSellerImg" alt="seller" v-b-tooltip.hover
              :title="filterTooltipVendedor" />
            <img class="user-icon2" v-if="filterSellerImg && sellerConfig && sellerIdent" 
              :src="filterSellerImg" alt="seller" v-b-tooltip.hover
              :title="filterTooltipVendedor" />
          </div>
        </div>
      </div>
      <p class="valor" v-if="pipeline_config['value_lead'] != 'false' ? true : false">
        R$ {{ formatMoney }}
      </p>
      <div class="item-flex" v-if="pipeline_config['follow_lead'] != 'false' ? true : false">
        <div v-if="lead.follow_up" class="icon-data">
          <svg class="color-red" v-if="lead.followUpClass == 'color-red'" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
            <path
              d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-50.34-74.34L139.31,152l18.35,18.34a8,8,0,0,1-11.32,11.32L128,163.31l-18.34,18.35a8,8,0,0,1-11.32-11.32L116.69,152,98.34,133.66a8,8,0,0,1,11.32-11.32L128,140.69l18.34-18.35a8,8,0,0,1,11.32,11.32Z">
            </path>
          </svg>
          <svg v-else :class="lead.followUpClass" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
            <path
              d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z">
            </path>
          </svg>
        </div>
        <div v-else class="icon-data">
          <svg class="color-gray" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
            <path
              d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Z">
            </path>
          </svg>
        </div>
        <p class="data p-follow-up " v-if="lead.follow_up" :class="lead.followUpClass">
          {{ lead.follow_up | moment("DD [de] MMMM YYYY HH:mm ") }}
        </p>
        <p v-else class="data">Sem Follow-Up</p>
      </div>

      <div v-if=" pipeline_config['companies_value'] != 'false' ? true : false" 
        class="item-flex">
        <img style="width: 16px; height: 16px" src="@/assets/img/icons/iconCompany.svg" alt="whats" />
        <p class="data telefone">
          {{ returnCompany }}
        </p>
      </div>
      <div v-if=" pipeline_config['phone_pipeline'] != 'false' ? true : false " 
      class="whats">
        <img @click="redirectPhone()" v-if="lead && lead.phone" class="whats-icon"
          src="@/assets/icons/whats-black.svg" alt="whats" />
        <h1 class="telefone" @click="redirectPhone()">
          {{ returnNumber }}
        </h1>
      </div>
      <div class="for-tag" v-if="pipeline_config['tag_lead_pipeline'] != 'false' ? true : false">
        <div v-for="(tag, index) in filterLoopTag" :key="index">
          <div class="tag-container" v-if="index < 6">
            <p class="tag" :class="tag.color == null ? 'default_color' : tag.color + '_color' ">
              {{ tag.name }}
            </p>
          </div>
        </div>
      </div>
      <div class="lead-actions" v-if="item == lastColumns">
        <BaseButton v-if="winSituation" variant="outline-primary" 
        @click="sendLeadBoard(), openModal('modal-business-pipeline'), reasonType('gain')" class="btn-lead">
          Ganho
        </BaseButton>
        
        <BaseButton v-if="lostSituation" 
          class="btn-lead" variant="outline-danger" 
          @click="sendLeadBoard(), openModal('modal-business-pipeline'), reasonType('loss')">
          Perdido
        </BaseButton>

        <BaseButton v-if="reopenSituation"
        class="btn-lead" variant="outline-info" 
        @click="gainAction('reabrir'), sendLeadBoard()">
          Reabrir
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import moment from "moment-timezone";

import ActivitieService from "@/services/resources/ActivitieService";
const serviceActivitie = ActivitieService.build();

import Vue from "vue";

export default {
  
  props: ["lead", "ctrl_item", "lead_click", "pipelines", "pipeline_config", "selectedPipeline", "sellerConfig", "level", "configSellerCanTransferLead", "user", "currentChecks", "index", "item", "sellers", "isIframe" ],
  components: {
    BaseButton
  },
  methods: {
    reasonType(reason) {
      this.$emit('update:reasonType', reason)
    },
    openModalCardSeller() {
      if (
        this.level === "seller" &&
        this.configSellerCanTransferLead == "true"
      ) {
      this.$grToast.toast("Sem autorização", {
        title: "CRM",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
      } else {
        this.lead.colum_id = this.item;
        this.$emit('update:seller_selected_card', this.lead)
        this.$bvModal.show('ModalEditarSeller');
      }
    },
    openModalIdLead() {
      this.$emit('update:id_lead_selected', this.lead);
    },
    openModal(type) {
      if (this.lead && type === "MoverLead") {
        this.$emit('update:totalPagina', 9999)
        this.updateLeadsToView();
      }
      this.$bvModal.show(type);
      this.$emit('update:valor_tag_colum', parseInt(this.lead));
      this.$emit('update:selected_lead_action', this.lead);
      this.$emit('update:leadsBoard', this.item);
    },
    openLead() {
        let url = `${process.env.BASE_URL}${this.user.tenant_subdomain}/lead/${this.lead.id}`;
        window.open(url, "_blank");
    },
    redirectPhone() {
      const formatPhone = this.lead.phone.replace(/\D/g, "");

      if(this.isIframe){
        let data ={
          type:'openLead',
          href: `https://wa.me/${formatPhone}`,
        }
        this.$emit('returnPostMensage', data)
        return
      }
      let url = `https://api.whatsapp.com/send?phone=${formatPhone}&text=Olá, ${this.lead.name}`;
      window.open(url, "_blank");
    }, 
    gainAction(type) {
      Vue.swal({
        title: "Lead",
        text: `Deseja ${
          type == "ganho" ? "definir o lead como ganho" : "reabrir o lead"
        }?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            id: "lead/change-status",
            lead_id: this.lead.id,
            pipeline_id: this.selectedPipeline,
          };
          if (type == "ganho") {
            data.reason = "Lead ganho";
            data.status = "done";
          } else {
            data.reason = "Lead reaberto";
            data.status = "active";
          }

          serviceActivitie.createId(data).then(() => {
            this.$grToast.toast(
              type == "ganho" ? "Oportunidade ganha!" : "Lead reaberto!",
              {
                title: "Lead",
                variant: "info",
                autoHideDelay: 300,
                appendToast: true,
              }
            );
            this.$emit('reloadEspecificColumn', this.item);
          });
        }
      });
    },
    selectLead(index) {
      if (event.ctrlKey || event.metaKey) {
        let exist = this.lead_click.find(
          (leadFind) => leadFind.id === this.lead.id
        );

        if (exist) {
          // desselecionar
          let index = this.lead_click.indexOf(exist);
          this.lead_click.splice(index, 1);

          // remover objetos vazios
          this.lead_click = this.lead_click.filter(
            (lead) => Object.keys(lead).length > 0
          );
        } else {
          // selecionar
          this.lead.colum_id = this.item;
          this.lead.colum_index = index;
          if (this.ctrl_item.indexOf(this.item) === -1) {
            this.ctrl_item.push(this.item);
          }
          this.lead_click.push(this.lead);
        }
      }
    },
    sendLeadBoard () {
      this.$emit('update:leadsBoard', this.lead);
    },
    changeUrl(url) {
      let num = Math.random();
      return url + "?v=" + num;
    },
    verifySituation(pipeline_id, situations) {
      return situations.find((situation) => situation.pipeline_id == pipeline_id);

    },
  },
  computed: {
    winSituation() {
      let result = this.verifySituation(this.selectedPipeline, this.lead.situations);
      return !result || (result && result?.situation !== "done");
    },
    reopenSituation() {
      let result = this.verifySituation(this.selectedPipeline, this.lead.situations);
      return result && result?.situation !== "active";
    },
    lostSituation() {
      let result = this.verifySituation(this.selectedPipeline, this.lead.situations);
      return !result || (result && result?.situation !== "inactive" && result?.situation !== "done");
    },
    lastColumns() {
      let lastColumn = this.pipelines.find((x) => x.id == this.selectedPipeline).tags;
      if (this.selectedPipeline) {
        let lastIndex = lastColumn.length - 1;
        let lastElement = lastColumn[lastIndex];
        return lastElement;
      } else {
        return [];
      }
    },
    formatMoney() {
      if (!this.lead || this.lead.products?.length === 0) {
        return "0,00"
      }
      let total = 0;
      this.lead.products.forEach(function (value) {
        total += value.net_value;
      });
      let number = total;
      number = parseFloat(number);
      if (typeof number !== "undefined" && number)
      return number.toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    filterTooltipVendedor() {
      let seller = this.sellers;
      let search = seller.find((x) => x.id == this.lead.user_id);
      if (this.sellers) {
        if (search && search.email) {
          return search.email;
        } else {
          return "Não Atribuído";
        }
      }
    },
    filterLoopTag() {
      let arrayTag = [];
      let tags = this.lead.tags;
      for (let x = 0; x < tags.length; x++) {
        let lead = tags[x];
        arrayTag.push(lead);
      }
      return arrayTag;
    },
    getMetaInitialLead() {
      let leadName = this.lead.name
      if (leadName) {
        let name = leadName.split(" ")[0];
        let last_name = leadName.split(" ")[1] ?? undefined;
        if (!name && !last_name) {
          return '';
        }
        if (!last_name) {
          return name.substring(0, 2);
        }
        return name[0] + last_name[0];
      }
      return;
    },
    filterSellerImg() {
      let sellers = this.sellers;
      let findId = sellers.find((x) => x.id == this.lead.user_id);

      if (findId && findId.metas) {
        for (let i = 0; i < findId.metas.length; i++) {
          let lead = findId.metas[i];
          if (lead.meta_key == "photo") {
            if (lead.meta_value === "") {
              return "no-photo";
            } else if (typeof lead.meta_value === "string") {
              return this.changeUrl(lead.meta_value);
            } else {
              return "no-photo";
            }
          }
        }
      }
      return;
    },
    returnNumber() {
      if (this.lead) {
        if (this.lead.phone) {
          if (this.lead.phone.match(/\+55/gi)) {
            if (this.lead.phone !== null || (this.lead.phone !== undefined && this.lead.phone.length > 0)) {
              this.lead.phone = this.lead.phone.replace(/\D/g, "");
              this.lead.phone = this.lead.phone.replace(/^(\d)/, "+$1");
              this.lead.phone = this.lead.phone.replace(/(.{3})(\d)/, "$1($2");
              this.lead.phone = this.lead.phone.replace(/(.{6})(\d)/, "$1)$2");
              if (this.lead.phone.length == 12) {
                this.lead.phone = this.lead.phone.replace(/(.{1})$/, "-$1");
              } else if (this.lead.phone.length == 13) {
                this.lead.phone = this.lead.phone.replace(/(.{2})$/, "-$1");
              } else if (this.lead.phone.length == 14) {
                this.lead.phone = this.lead.phone.replace(/(.{3})$/, "-$1");
              } else if (this.lead.phone.length == 15) {
                this.lead.phone = this.lead.phone.replace(/(.{4})$/, "-$1");
              } else if (this.lead.phone.length > 15) {
                this.lead.phone = this.lead.phone.replace(/(.{4})$/, "-$1");
              }
              return this.lead.phone;
            }
          }
          return this.lead.phone;
        }
      } else {
        return "";
      }
    },
    returnCompany () {
      if (this.lead.companies && this.lead.companies.length && this.lead.companies[0] && this.lead.companies[0].name != undefined) {
        return this.lead.companies[0].name;
      } else {
        return "Sem empresa";
      }
    },
    filterSellerNoImg() {
      let sellers = this.sellers;
      let findId = sellers.find((x) => x.id == this.lead.user_id);
      let name = findId?.first_name ?? "";
      let lastName = findId?.last_name ?? "";

      if (!name || !lastName) {
        return "";
      }
      return name[0] + lastName[0];
    },
    photoLead () {
      let metaValue = this.lead.metas?.map(meta => meta.meta_value);
      return this.lead.metas && this.lead.metas.length > 0 && metaValue;
    },
    getLeadphoto() {
      let metaValue = this.lead?.metas.map(meta => meta.meta_value);

      let photo = metaValue;
      return photo;
    },
    isSelected () {
      return this.lead_click.findIndex((item) => item.id === this.lead.id) !== -1;
    },
    inStyle () {
      return this.currentChecks.includes(JSON.stringify(this.lead.id)) ? 'opacity: 0.6; z-index: 10;' : '';
    },
    isSellerInvalid () {
      return ((this.sellerConfig && this.sellerConfig.vendorCanTransferLead == true) || this.level != 'seller') && this.lead.user_id && this.filterSellerNoImg != '';
    },
    isSellerValid () {
      return this.sellerConfig && this.sellerConfig.vendorCanTransferLead == false && this.level == 'seller' && this.lead.vendedor && this.filterSellerNoImg != ''
    },
    sellerIdent () {
      return this.sellerConfig.vendorCanTransferLead == false && this.level == 'seller';
    },
    sellerNoIdent () {
      return (this.sellerConfig && this.sellerConfig.vendorCanTransferLead == true) || this.level != 'seller'
    }
  },
}
</script>

<style lang="scss" scoped>
.check-icon {
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  background-color: white; 
  opacity: 0; 
  border-radius: 10px; 
  transition: all 0.4s;
  z-index: -1;
}
.container-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 5px;
  margin-top: -10px;
}

.list-group-item[data-v-9e0f67e2] {
  box-shadow: 0px 2px 8px #0001;
}
.list-group-item {
  cursor: grab;
  margin: 8px 8px 0px 8px;
  padding: 20px;
  border-radius: 10px !important;
  border: none;
  transition: 0s;
  position: relative;
}
.container-add:hover {
  .add-lead {
    display: grid;
  }
}
.container-add {
  width: 30px;
  height: 30px;
  background: transparent;
  position: absolute;
  top: -20px;
  left: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-lead {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid #b1b1b1;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: none;
}
.lead-no-photo {
  margin: 0 !important; 
  border: var(--greenn) ,1px, solid;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: var(--greenn-transparent);
  display: flex;
  align-items: center;
  justify-content: center;
}
.lead-no-photo h3 {
  text-align: center; 
  color: var(--greenn); 
  padding-top: 7px; 
  font-size: 16px; 
  text-transform: uppercase;
}
.lead-photo img {
  border-radius: 50%; 
  width: 36px; 
  height: 36px;
}
.name-visualizer {
  padding-top: 10px;
  @media (max-width: 768px) {
    .item-flex {
      display: none !important;
      visibility: hidden;
      pointer-events: none;
    }
  }
}
.nome {
  font-weight: 500;
  font-size: 16px;
  color: var(--gray01);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
  line-height: 20px;
  padding-right: 8px;
  margin: 0;
  border-radius: 3px;
}
.item-flex {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: fit-content;
  margin-bottom: 10px;
}
.visualizar-lead {
  color: var(--greenn);
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  cursor: pointer;
  margin: 2px;
  a {
    color: transparent;
    transition: color 0.3s;
  }
  &:hover a {
    color: var(--greenn);
  }
}
.seller-no-ft3 {
  border-radius: 50%;
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 0px;
  cursor: pointer;
  background: #81858e;
  top: 24px;
  right: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.info_color {
  background: var(--bluee);
}
.danger_color {
  background: var(--red);
}
.warning_color {
  background: var(--orange);
}
.primary_color {
  background: var(--greenn-light);
}
.success_color {
  background: #2ed7ee;
}
.default_color {
  background: var(--gray01);
}

.default-color {
  background: rgba(170, 170, 170, 0.301);
  padding: 3px;
  border-radius: 10%;

}
.seller-no-ft3 h3 {
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 13px;
  padding-top: 7px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.user-icon2 {
  border-radius: 50%;
  height: 29px;
  width: 29px;
  cursor: pointer;
  padding: 0px;
  cursor: pointer;
  top: 23px;
  right: 19px;
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 3;
  object-fit: cover;
  background-color: #fff;
}
.user-icon {
  width: 26px;
  height: 26px;
  padding: 0px;
  cursor: pointer;
  top: 24px;
  right: 21px;
  position: absolute;
  z-index: 1;
}
.valor {
  font-weight: 400;
  font-size: 14px;
  color: #b1b1b1;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.icon-data {
  display: flex;
}
.icon-data svg{
  width: 16px;
  height: 19px;
}
.color-alert {
  color: var(--orange-dark) !important;
  fill: var(--orange-dark)
}
.color-red {
  color: var(--red) !important;
  fill: var(--red)
}
.color-greenn{
  color: var(--greenn) !important;
  fill: var(--greenn)
}
.color-gray{
  color: var(--gray03) !important;
  fill: var(--gray03)
}
.p-follow-up{
  font-weight: 500;
}
.data {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #81858e;
  margin: 2px;
}
.telefone {
  font-weight: 400;
  font-size: 12px;
  color: #81858e;
}
.whats {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: -0px;
  margin-top: -10px;
  img,
  h1 {
    cursor: pointer;
  }
}
.tag {
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  padding-left: 8px;
  padding-right: 8px;
  margin: 0;
  border-radius: 3px;
  transition: all 0.3s;
}
.tag-container {
  border-radius: 10px;
}
.for-tag {
  display: flex;
  flex-wrap: wrap;
  // gap: 5px;
  div {
    margin-right: 2px;
    margin-bottom: 2px;
  }
}

.lead-actions {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}
.btn-lead {
  height: 40px !important;
  width: 100% !important;
}
</style>