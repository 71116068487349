<template>
  <span class="leads-filter">
    <!-- filtros -->
    <BaseModal name="filter-pipe" size="lg" title="Filtros" @shown="openModal">
      <b-row v-if="!loading">
        <b-col cols="12">
          <div class="top-filter">
            <div>
              <p>Os seus filtros ficam aqui.</p>
            </div>
            <BaseButton
              variant="link-info"
              class="link-documentacao"
              id="limpar"
              @click="limparFiltros"
              >Limpar filtros
            </BaseButton>
          </div>
          <!-- Selecione as datas -->
          <b-form novalidate>
            <div class="add-link-body label-date"></div>
            <b-row>
              <b-col cols="6">
                <label class="label-filter"> Selecione as datas </label>
                <div class="datas">
                  <date-range-picker
                    ref="picker"
                    opens="right"
                    :locale-data="localeDate"
                    :showWeekNumbers="false"
                    :showDropdowns="true"
                    :autoApply="false"
                    :ranges="dateRanges"
                    v-model="dateRange1"
                  >
                    <template
                      v-if="dateRange1.startDate && dateRange1.endDate"
                      v-slot:input="picker"
                      style="min-width: 350px"
                    >
                      {{ picker.startDate | date }} -
                      {{ picker.endDate | date }}
                    </template>
                    <template
                      v-else
                      v-slot:input
                      class="btn-table"
                      title="Selecionar datas"
                    >
                      <!-- último cadastro -->
                    </template>
                  </date-range-picker>
                </div>
              </b-col>
              <b-col cols="6">
                <div class="add-link-body">
                  <label class="label-filter"> Ordenar por: </label>
                </div>
                <BaseSelect
                  id="type"
                  :selectModel="type_date"
                  :array="types_list"
                  trackid="value"
                  trackname="text"
                  returnid="value"
                  selectLabel=""
                  deselectLabel=""
                  @change="changedType"
                  style="width: 100%"
                />
              </b-col>
            </b-row>

            <!-- tags marcadas e nao marcadas -->
            <b-row class="mt-4">
              <b-col cols="6">
                <div class="add-link-body">
                  <label class="label-filter"> Tags marcadas </label>
                  <router-link
                    v-if="!tags.length && verificUserLevel"
                    :to="{ name: 'Tags' }"
                    class="add-link"
                    >Deseja criar uma tag?</router-link
                  >
                </div>

                <BaseSelect
                  id="tag"
                  :selectModel="tagMarcadas"
                  placeholder="Selecione uma tag"
                  track-by="id"
                  trackname="name"
                  specificType="tags"
                  :array="tags"
                  :multiple="true"
                  :searchable="true"
                  selectLabel=""
                  deselectLabel=""
                  noResult="Oops! Nenhuma tag encontrada."
                  noOptions="Pesquise por uma Tag"
                  selectedLabel=""
                  :watch="true"
                  @search="debounceTags($event)"
                  :loading="loadingTags"
                  @callback="getTags($event)"
                  @change="decideTagsFilter($event)"
                >
                </BaseSelect>
              </b-col>
              <b-col cols="6">
                <div class="add-link-body">
                  <label class="label-filter"> Tags não marcadas </label>
                  <router-link
                    v-if="!tags.length && verificUserLevel"
                    :to="{ name: 'Tags' }"
                    class="add-link"
                    >Deseja criar uma tag?</router-link
                  >
                </div>
                <BaseSelect
                  id="tag_exit"
                  :selectModel="tag_nao_marcadas"
                  track-by="id"
                  trackname="name"
                  specificType="tags"
                  :array="tags.filter(tag => tag.id != 0)"
                  :multiple="true"
                  :searchable="true"
                  selectLabel=""
                  placeholder="Selecione uma tag"
                  deselectLabel=""
                  noResult="Oops! Nenhuma tag encontrada."
                  noOptions="Pesquise por uma Tag"
                  selectedLabel=""
                  @search="debounceTags($event)"
                  :loading="loadingTags"
                  @callback="getTags($event)"
                  @change="tag_nao_marcadas = $event"
                >
                </BaseSelect>
              </b-col>
            </b-row>

            <!-- formularios -->
            <b-row>
              <b-col cols="6" class="mt-2">
                <div class="add-link-body">
                  <label class="mt-3">Formulário</label>
                  <router-link
                    v-if="!formulario.length && verificUserLevel"
                    :to="{ name: 'CampanhasLista' }"
                    class="add-link"
                    >Deseja criar um formulário?</router-link
                  >
                </div>
                <!-- <Multiselect
                  placeholder="Selecione o formulário"
                  track-by="id"
                  label="title"
                  :options="formulario"
                  v-model="formulario_fill"
                  :multiple="true"
                  :taggable="true"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  @change="formulario_fill = $event"
                  ><span slot="noResult">Nenhum formulário encontrado.</span>
                </Multiselect> -->

                <BaseSelect
                  id="tag_exit"
                  :selectModel="formulario_fill"
                  placeholder="Selecione o formulário"
                  track-by="id"
                  trackname="title"
                  :multiple="true"
                  :array="formulario"
                  selectLabel=""
                  deselectLabel=""
                  :loading="loadingForms"
                  @search="debounceForms($event)"
                  @callback="getForms($event)"
                  @change="formulario_fill = $event"
                  ><span slot="noResult">Nenhum formulário encontrado.</span>
                </BaseSelect>
              </b-col>
              
              <b-col v-if="hasPreSeller == 'true'" cols="6" class="mt-2">
                <div class="add-link-body">
                  <label class="mt-3">Pré-Vendedor</label>
                  <router-link
                      v-if="userLevel == 'owner' || userLevel == 'commercial_leader' || userLevel == 'admin'"
                      :to="{ name: 'Usuarios' }"
                      class="add-link"
                      >Deseja criar um pré-vendedor?</router-link
                    >
                </div>

                <Multiselect
                  placeholder="Selecione um pré-vendedor"
                  track-by="id"
                  label="email"
                  :options="setNotUser"
                  v-model="preVendedor"
                  :multiple="true"
                  :taggable="true"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  @change="preVendedor = $event"
                  ><span slot="noResult">Nenhum pré-vendedor encontrado.</span>
                </Multiselect>
              </b-col>
            </b-row>
            <!-- leadscore -->
            <b-row class="mt-2" v-if="recursos.nscore >= 1">
              <b-col cols="6" class="mt-2">
                <b-form-group
                  label="Temperatura"
                  label-for="method"
                  class="form-relative mb-4"
                >
                  <div class="temp-inputs">
                    <div>
                      <input type="number" v-model="leadscore[0]">
                    </div>
                    <div>
                      <input type="number" v-model="leadscore[1]">
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="6" class="mt-2">
                <b-form-group
                  label="Motivos"
                  label-for="method"
                  class="form-relative mb-4"
                >
                <div style="display: flex;gap: 5px;">
                  <BaseSelect
                    id="entry_reason"
                    :selectModel="entryReason"
                    :array="entryReasons"
                    trackid="value"
                    trackname="text"
                    returnid="value"
                    selectLabel=""
                    :allowEmpty="false"
                    deselectLabel=""
                    @change="changeEntryReason"
                    style="width: 45%;"
                  />
                <BaseSelect
                  id="reason_text"
                  :selectModel="reasons"
                  :array="reasonList"
                  placeholder="Selecione o motivo"
                  track-by="id"
                  trackname="reason"
                  :multiple="true"
                  :disabled="reasonDisabled"
                  selectLabel=""
                  deselectLabel=""
                  :watch="true"
                  @search="debounceReasons($event)"
                  @callback="getReasons($event)"
                  @change="reasons = $event"
                  ><span slot="noResult">Nenhum formulário encontrado.</span>
                </BaseSelect>  
                </div>              
              </b-form-group>
              </b-col>
            </b-row>
            <!-- formularios -->
            <b-row>
              <b-col cols="12" class="mt-2">
                <div class="add-link-body">
                  <label class="mt-3">Empresas</label>
                </div>
                <BaseSelect
                  :clear="clear"
                  :selectModel="company"
                  label=""
                  id="ajax"
                  trackname="name"
                  track-by="id"
                  placeholder="Selecionar empresa"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  open-direction="bottom"
                  :array="companies"
                  :multiple="true"
                  :searchable="true"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  :loading="loadingCompany"
                  @search="debounceCompany"
                  @callback="getCompanies($event)"
                  @change="company = $event"
                >
                  <span slot="noOptions">Pesquise pela empresa</span>

                  <span slot="noResult">Oops! Nenhuma empresa encontrada.</span>
                </BaseSelect>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" class="mr-4" @click="cancel">
          Cancelar
        </BaseButton>
        <BaseButton variant="primary" @click="onSubmit"> Filtrar </BaseButton>
      </template>
    </BaseModal>
  </span>
</template>
<script>
import Multiselect, { multiselectMixin } from "vue-multiselect";
import _ from "lodash";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment-timezone";

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

import EmailService from "@/services/resources/EmailService";
const serviceEmail = EmailService.build();

import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();

import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import CompanyService from "@/services/resources/CompanyService";
const serviceCompany = CompanyService.build();
import ListarTagService from "@/services/resources/ListarTagService";
const serviceListarTag = ListarTagService.build();

export default {
  props: ["tagList", 'recursosData', 'userLevelData', 'isIframe','users'],
  created () {
    if (this.isIframe) {
      this.recursos = this.recursosData
      this.userLevel = this.userLevelData
    } else {
      this.recursos = this.$store.getters.recursos
      this.userLevel = this.$store.getters.user.user.level
    };
  },
  components: {
    Multiselect,
    DateRangePicker,
    multiselectMixin,
  },
  data() {
    return {
      tags: [
        {
          id: 0,
          name: "Nenhuma tag marcada",
          color: "default"
        }
      ],
      reasonList: [],
      entryReason:'all',
      reasons:[],
      entryReasons:[
        { value:'all',text:"Todos"},
        { value:"done",text:"Ganhos"},
        { value:"inactive",text:"Perdas"}
      ],
      stop1: false,
      stops: [],
      hasPreSeller: true,
      recursos: {},
      userLevel: 'admin',
      broadcast: false,
      filter2: true,
      formulario: [],
      formulario_fill: [],
      loading: false,
      tagMarcadas: [],
      preVendedor:[],
      tag_nao_marcadas: [],
      leadscore: [undefined, undefined],
      adicional: [{ chave: "", valor: "" }],
      type_date: "register",
      types_list: [
        { value: "register", text: "Último cadastro" },
        { value: "created_at", text: "Entrada do lead" },
        { value: "user_updated_at", text: "Associação ao vendedor" },
        { value: "follow_up", text: "Data do follow-up" },
      ],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange1: {
        startDate: moment(new Date(2015, 0, 1)),
        endDate: moment(),
      },
      render: false,
      companies: [],
      company: [],
      stops: [],
      clear: false,

      //tags
      searchTags: "",
      stopTags: false,
      loadingTags: false,

      //forms
      stopForm: false,
      stopReasons: false,
      searchForm: "",
      searchReasons:"",
      loadingForms: false,
      loadingReasons: false,
      loadingPreSellers:false,
      //company
      loadingCompany: false,
      searchCompany: "",
      stopCompany: false,
    };
  },
  computed: {
    verificUserLevel() {
      if (this.userLevel === "owner" || this.userLevel === "admin" || this.userLevel == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    reasonDisabled(){
      if(this.reasonList.length > 0){
        return false;
      }
      return true;
    },
    setNotUser(){
      if(!this.users.find(x => x.id == 0)){
        this.users.unshift({id: 0,email: "Sem vendedor"})    
      }
      return this.users;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear()-1, 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2015, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },
  methods: {
    debounceCompany: _.debounce(function (query) {
      if (query === "") {
        this.loadingCompany = false;
      } else {
        this.searchCompany = query;
        this.getCompanies(1, query);
      }
    }, 500),
    changeEntryReason(event) {
      this.entryReason = event;
      this.stopReasons = false;
      this.reasons = [];
      this.reasonList = [];
      if(event == 'all') return;
      this.getReasons();
    },
    getCompanies(page = 1, query = "") {
      if (this.stopCompany == true) {
        return;
      }
      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}`,
      };

      serviceCompany
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopCompany = true;
          }
          this.companies = this.companies.concat(resp.data);
          this.companies = this.companies.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingCompany = false;
        });
    },

    debounceTags: _.debounce(function (query) {
      this.searchTags = query;
      this.stopTags = false;
      this.getTags(1, query);
    }, 500),
    getTags(page = 1, query = "") {
      if (this.stopTags == true) {
        return;
      }

      this.loadingTags = true;

      serviceListarTag
        .read(`?page=${page}&order_by=name&order=ASC&search=${query}`)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stopTags = true;
          }

          this.tags = this.tags.concat(resp.data);
          this.tags = this.tags.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((err) => {})
        .finally(() => {
          this.loadingTags = false;
        });
    },
    decideTagsFilter(tags) {
      const hasNoTagFilter = tags.filter(tag => tag.id == 0);
      if(hasNoTagFilter.length){
        this.tagMarcadas = hasNoTagFilter;
        return;
      }

      this.tagMarcadas = tags;
    },
    debounceForms: _.debounce(function (query) {
      if (query === "") {
        this.loadingForms = false;
      } else {
        this.searchForm = query;
        this.getForms(this.pageForm, query);
      }
    }, 500),
    debounceReasons: _.debounce(function (query) {
      if (query === "") {
        this.loadingReasons = false;
      } else {
        this.searchReasons = query;
        this.getReasons(this.pageForm, query);
      }
    }, 500),
    getReasons(page = 1, query = "") {
      if (this.stopReasons == true || this.entryReason == 'all') {
        return;
      }
      var data = {
        id: `${this.entryReason == 'done' ? 'reasons/gain/list' : 'reasons/list'}?page=${page}&search=${query}`,
      };
      serviceSettings
        .read(data)
        .then((resp) => {
          if (resp.data && resp.data.length < resp.per_page && query == "") {
            this.stopReasons = true;
          }
          this.reasonList = this.reasonList.concat(resp.data);
          this.reasonList = this.reasonList.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingReasons = false;
        });
    },
    getForms(page = 1, query = "") {
      if (this.stopReasons == true) {
        return;
      }
      var data = {
        id: `?page=${page}&global_scope=true&order_by=title&status=active&order=ASC&search=${query}`,
      };
      serviceForm
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopForm = true;
          }
          this.formulario = this.formulario.concat(resp.data);
          this.formulario = this.formulario.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingForms = false;
        });
    },

    changedType(event) {
      this.type_date = event;
    },
    limparFiltros() {
      this.loading = true;
      this.reasonType = 'all';
      this.stopReasons = false;
      this.reasons = [];
      this.reasonList = [];
      this.formulario_fill = [];
      this.filter2 = true;
      this.tagMarcadas = [];
      this.tag_nao_marcadas = [];
      this.leadscore = [undefined, undefined];
      this.dateRange1.startDate = moment(new Date(2015, 0, 1));
      this.dateRange1.endDate = moment();
      this.type_date = "register";
      this.company = [];
      this.preVendedor = [],
      this.clear = true;
      setTimeout(() => {
        this.clear = false;
        this.loading = false;
      }, 500);
    },
    onSubmit() {
      let data = {
        tag: this.tagMarcadas.length
          ? this.tagMarcadas.map(function (item) {
              return item.id + "";
            })
          : [],
        tag_exceto: this.tag_nao_marcadas.length
          ? this.tag_nao_marcadas.map(function (item) {
              return item.id + "";
            })
          : [],
        formulario: this.formulario_fill.length
          ? this.formulario_fill.map(function (item) {
              return item.id + "";
            })
          : [],
        preVendedor: this.preVendedor.length
        ? this.preVendedor.map(function (item) {
            return item.id + "";
          })
        : [],
        leadscore_maximo: this.leadscore[1] ?? undefined,
        leadscore_minimo: this.leadscore[0] ?? undefined,

        data_inicial: this.filter2
          ? moment
              .tz(this.dateRange1.startDate, "America/Sao_Paulo")
              .format("YYYY-MM-DD")
          : [],
        data_final: this.filter2
          ? moment
              .tz(this.dateRange1.endDate, "America/Sao_Paulo")
              .format("YYYY-MM-DD")
          : [],

        campo_data: this.type_date,
        reasons: this.reasons.length
        ? this.reasons.map(function (item) {
            return item.reason + "";
          })
        : [],
        reasonType: this.entryReason == 'all' ? null : this.entryReason,
        company:
          this.company && this.company.length
            ? this.company.map(function (item) {
                return item.id + "";
              })
            : [],
        company_view: this.company && this.company.length ? true : false,
      };

      this.$emit("filter", data);

      this.$bvModal.hide("filter-pipe");
    },
    fetchPipelines() {
      this.pipelines = [];

      servicePipeline
        .search()
        .then((response) => {
          response.forEach((element) => {
            this.pipelines.push({
              id: element.ID,
              name: element.titulo,
              tags: element.tags,
            });
          });
        })
        .catch((err) => {
          // console.log(err);
          this.pipelines = [];
        });
    },
    configPreSeller(){
      
      let data = {
        id: "meta/has_pre_seller",
      };
      serviceSettings.read(data).then((resp) => {
        this.hasPreSeller = resp.meta_value;
      });
    },
    async openModal() {
      this.configPreSeller();
      this.filter2 = true;
      try {
        this.loading = true;
        // await this.getTags();
        // await this.getForms();
        this.render = true;
        this.loading = false;
      } catch (error) {
        // Lide com erros aqui, se necessário
        console.error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.temp-inputs{
  display: flex;
  justify-content: space-between;
  div {
    width: calc(50% - 10px) !important;
    position: relative;
    @mixin fake-labels($text) {
      content: $text;
      position: absolute;
      top: 0;
      left: 15px;
      font-size: 12px;
      color: var(--white-dark);
      padding: 0 5px;
      background-color: white;
      transform: translateY(-50%);
      transition: .3s
    }
    &:first-child::after {
      @include fake-labels('min')
    }
    &:last-child::after {
      @include fake-labels('max')
    }
    &:has(input:hover)::after, &:focus-within::after {
      color: var(--greenn);
    }
    input {
      width: 100%;
      margin-bottom: 0 !important;
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}

p + p {
  margin-top: 5px;
}

.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .top-filter {
    flex-direction: column;
    align-items: inherit;
    display: block;
  }

  .top-filter #limpar {
    margin-top: 15px;
  }
}

.form-relative {
  position: relative;
}

.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}

.btn-adicional {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 30px;
  padding-right: 15px;
}

.margin-btn-delete {
  margin-top: 26px;
  height: 59px;
}

.card-validation {
  width: 100%;
  background: rgba(33, 51, 210, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 30px;
  display: flex;
}

.card-validation p {
  color: var(--bluee);
  font-size: 13px;
  line-height: 1.5;
}

.vue-daterange-picker {
  display: block !important;
}

/* .label-date {
  width: 354px;
  margin:0;
} */
</style>
