import Rest from '@/services/Rest';

/**
 * @typedef {LeadValuePipelineService}
 */
export default class LeadValuePipelineService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'lead/list/value'
}