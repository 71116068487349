import Rest from "@/services/Rest";

/**
 * @typedef {MoverLeadsService}
 */
export default class MoverLeadsService extends Rest {
  /**
   * @type {String}
   */
  static resource = "pipeline/automation";
}
