<template>
  <BaseModal
    name="MoverLead"
    id="MoverLead"
    idModal="MoverLead"
    size="lg"
    title="Mover leads"
    @shown="fetchPipelines"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Mover os Leads Selecionados para o Pipeline.</p>
          </div>
        </div>
        <div style="display: grid; gap: 15px">
          <b-form-group label="Selecione um CRM">
            <BaseSelect
              :selectModel="pipeline"
              trackname="title"
              track-by="id"
              :array="pipelines"
              selectLabel=""
              deselectLabel=""
              v-if="showComponentCrm"
              :loading="loadingCrm"
              @search="debounceCrm"
              @callback="getPipelines($event)"
              @change="pipeline = $event, selectedPipeline($event)"
              ><span slot="noResult">Nenhum pilpeline encontrado.</span>
            </BaseSelect>
          </b-form-group>
          <b-form-group label="Selecione uma tag">
            <multiselect
              label="name"
              v-model="tag"
              :options="tags"
              track-by="id"
              placeholder="Selecione uma tag"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :allow-empty="false"
              :searchable="true"
              :disabled="!pipeline"
            />
          </b-form-group>

          <p class="d-block mt-3">
            Aviso: Serão desconsiderados os leads que já estiverem associados a
            3 CRMs diferentes do selecionado
          </p>
        </div>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Mover
      </BaseButton>
    </template>

    <div
      style="
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      "
      v-if="loading"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import Vue from "vue";


import MoverLeadsService from "@/services/resources/MoverLeadsService";
import PipelineService from "@/services/resources/PipelineService";

const serviceMoverLeads = MoverLeadsService.build();
const servicePipeline = PipelineService.build();

import TagService from "@/services/resources/TagService";
const serviceTag = TagService.build();

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

import ListarTagService from "@/services/resources/ListarTagService";
const serviceListarTag = ListarTagService.build();

import _ from "lodash";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      stopCrm: false,
      loadingCrm: false,
      searchCrm: "",
      showComponentCrm: true,
      pageCrm: 1,

      stop: false,
      tag: null,
      pipeline: null,
      pipeline_config: {},
      pipelines: [],
      tags: [{ id: null, name: "Selecione uma Tag" }],
      loading: false,
      leads2: [],
      contador: 0,
      ids: [],
      tags_storage: this.$store.getters.tags,
      tagsPipe: [],
    };
  },
  props: ["leads", "all_leads", "leadsBoard","filters", "isSelectedPipeline", "selectedBord"],
  computed: {},
  methods: {
    onSubmit(second) {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.pipeline) {
            this.$grToast.toast("Selecione um CRM", {
              title: "Automação CRM",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }

          if (!this.tag) {
            this.$grToast.toast("Selecione uma tag", {
              title: "Automação CRM",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }
          this.filters.board = [this.selectedBord]
          this.filters.pipeline = [this.isSelectedPipeline]
          this.$bvModal.hide("MoverLead");
          let data = {
            pipeline_id: this.pipeline.id,
            tag_id: this.tag.id,
            leads: "todos",
            filters: this.filters
          };

          Vue.swal({
            title: "CRM",
            text: `Tem certeza que deseja mover os leads?`,
            type: "confirm",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            customClass: "sweet-container",
            confirmButtonClass: "button mt-3 mb-3",
            cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
            buttonsStyling: false,
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              serviceMoverLeads
                .create(data)
                .then(() => {
                  this.$grToast.toast("Leads serão movidos em breve", {
                    title: "Automação CRM",
                    variant: "info",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });

                  localStorage.setItem("selectedPipeline", this.pipeline.id);
                  this.$emit("reload-list");
                  this.$emit("atualizar", data.tag_id);
                })
                .catch((err) => {
                  // console.log(err);
                  this.$grToast.toast("Erro ao mover Leads", {
                    title: "Automação CRM",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                  this.$bvModal.hide("pipeline-automation");
                })
                .finally(() => {
                  this.loading = false;
                  setTimeout(() => {
                    this.tag = "";
                    this.pipeline = "";
                  }, 1000);
                });
            }
          });
        }
      });
    },
    fetchConfigPipeline() {
      serviceSettings.read("/pipeline").then((resp) => {
        for (let i = 0; i < resp.length; i++) {
          const pipe = resp[i];
          if (pipe.meta_key == "tag_lead_pipeline") {
            this.pipeline_config = pipe.meta_value;
          }
        }
      });
    },
    selectedPipeline(event) {
      var result = this.pipelines.find((x) => x.id == event.id).tags;
      var tags = this.tagsPipe;

      var tags_board = [];
      for (let i = 0; i < result.length; i++) {
        const tag = result[i];
        tags_board.push(tags.find((x) => x.id == tag));
      }
      this.tags = tags_board;
    },

    debounceCrm: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentCrm();
      } else {
        this.searchCrm = query;
        this.pageCrm = 1;
        this.pipelines = [];
        this.stopCrm = "";
        this.getPipelines(this.pageCrm, query);
      }
    }, 500),
    resetComponentCrm() {
      this.pageCrm = 1;
      this.searchCrm = "";
      this.pipelines = [];
      this.stopCrm = "";
      this.showComponentCrm = false;
      this.$nextTick(() => {
        this.showComponentCrm = true;
      });
    },

    getPipelines(page = 1, query = "") {
      if (this.stopCrm) {
        return;
      }

      var data = {
        page: `${page}&order_by=title&order=ASC&search=${query}`,
      };

      servicePipeline
        .search(data)
        .then((response) => {
          var pipelines = response.data.map((element) => ({
            id: element.id,
            title: element.title,
            tags: element.tags,
          }));

          if (response.data.length < response.per_page) {
            this.stopCrm = true;
          }

          this.pipelines = this.pipelines.concat(pipelines);
          this.pipelines = this.pipelines.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((err) => {
          // this.pipelines = [];
        });
    },

    fetchPipelines() {
      this.tag = "";
      this.loading = true;
      this.ids = [];
      var data = {
        per_page: 9999,
      };
      serviceListarTag
        .search(data)
        .then((response) => {
          this.tagsPipe = response.data;
          this.loading = false;
        })
        .catch((error) => console.error(error));

      this.fetchConfigPipeline();
      this.pipeline = null;
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}

span {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
