<template>
  <BaseModal
    name="ModalAddTagPipeline"
    id="ModalAddTagPipeline"
    idModal="ModalAddTagPipeline"
    size="lg"
    title="Gestão de tags"
    @shown="openModal()"
    @hidden="clear()"
  >
    <div class="create-tag">
      <div class="tag-name">
        <label for="tagName">Dê um nome para a tag</label>
        <input
          id="tagName"
          type="text"
          placeholder="Nome da tag"
          v-model="nameTag"
        >
      </div>
      <div class="tag-color">
        <label>Escolha uma cor de destaque</label>
        <div class="mt-1 colors">
          <div v-for="color in colors" :class="`${color} ${color == selectedColor ? 'selected-color' : ''}`" @click="selectedColor = color">✓</div>
        </div>
      </div>
    </div>
    <div class="list-tag">
      <div 
        v-for="(tag, index) in tags"
        :key="index"
        class="tag"
        :class="selectedTag == tag.id ? 'selected-tag' : ''"
        @click="selectTag(tag)"
      >
        <div class="tag-info">
          <p class="name">{{ tag.name }} </p>
        </div>
        <div class="color-circle" :class="tag.color"></div>
      </div>
    </div>
    <template v-slot:footer="{ cancel }" >
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar tag
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import TagService from "@/services/resources/TagService";
const serviceTag = TagService.build();

export default {
  props:['tagsProps'],
  data () {
    return {
      tags: [],
      selectedColor: "",
      colors: ["primary","info","warning","danger", "default"],
      nameTag: "",
      loading: false,
      selectedTag: null,
      isEdit: false
    }
  },
  methods: {
    errorMessage(err){
      if (
        err.response.data.message == "Tag já existe!"
      ) {
        this.$bvToast.toast("Essa tag já está sendo usada", {
          title: "Gestão de tags",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else if (err.response.data.name == "The name field is required.") {
        this.$bvToast.toast("Insira o nome da tag", {
          title: "Gestão de tags",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        this.$bvToast.toast(`Erro ao ${this.isEdit ? 'atualizar' : 'criar nova'} tag`, {
          title: "Gestão de tags",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    openModal(){
      this.tags = this.tagsProps
      this.selectedColor = "primary"
    },  
    clear(){
      this.selectedColor = "primary"
      this.nameTag = ""
      this.selectedTag = null
      this.isEdit = false
    },
    selectTag(data){
      if(this.selectedTag == data.id){
        this.clear()
        return
      }
      this.nameTag = data.name
      this.selectedColor = data.color
      this.selectedTag = data.id
      this.isEdit = true
    },
    onSubmit(){
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.loading = false
          return
        }
        let data = {
          name: this.nameTag,
          description: '',
          color: this.selectedColor,
        };

        if (this.isEdit == true){
          data.id = `edit/${this.selectedTag}`;
          serviceTag
            .update(data)
            .then((resp) => {
              this.$bvToast.toast("Tag atualizada com sucesso", {
                title: this.origem_dadosTag ? "Pipeline" : "Gestão de tags",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              let index = this.tags.findIndex(tag => tag.id === this.selectedTag);
              if (index !== -1) {
                this.tags.splice(index, 1, resp.tag);
              }
            })
            .catch((err) => {
              this.errorMessage(err)
            })
            .finally(() => {
              this.loading = false;
              this.$bvModal.hide('ModalAddTagPipeline')
            });
          return;
        }
        serviceTag
          .create(data)
          .then((resp) => {
            this.$bvToast.toast("Tag criada com sucesso", {
              title: "Gestão de tags",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.tags.unshift(resp.tag)
          })
          .catch((err) => {
            this.errorMessage(err)
          })
          .finally(() => {
            this.loading = false;
            this.$bvModal.hide('ModalAddTagPipeline')
          });
      })
    },
  }
}
</script>
<style scoped lang="scss">
$tag-colors: (
  info:  var(--bluee),
  danger: var(--red),
  warning: var(--orange),
  primary: var(--greenn-light),
  default: rgb(37, 37, 37),
);
@each $tag, $color in $tag-colors {
  .#{$tag} {
    color: ($color);
    background-color: ($color);
    border-color: ($color) !important;
  }
}
.colors {
  display: flex;
  gap: 10px;
  > div {
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    font-size: 25px;
    border-radius: 7px;
    border: 1px solid #000;
    transition: all .3s;
    cursor: pointer;
    &:hover {
      box-shadow: inset 0 0 0px 2.5px #fff;
    }
  }
  .selected-color {
    box-shadow: inset 0 0 0px 2.5px #fffb, 2px 2px 3px 1px #0002;
    color: #fffb !important;
  }
}
.selected-tag {
  background-color: #dddb !important
}
.create-tag{
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 20px;
  width: 100%;
  .tag-name{
    input {
      width: 100%;
    }
  }
}
.list-tag{
  font-weight:500; 
  overflow: hidden auto;
  max-height: 400px;
  margin-top: 10px;
  scrollbar-color: var(--greenn2) transparent !important;
  .tag{
    display: inline-flex;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    background-color: #f7f7f7;
    margin: 5px;
    padding: 20px;
    cursor: pointer;
    .tag-info{
      .name {
        max-width: 120px !important;
        white-space:nowrap !important;      
        overflow: hidden !important;         
        text-overflow: ellipsis !important;
      }
    }
    .color-circle{
      width: 13px;
      height: 13px;
      border-radius: 100px;
    }
  }
}
</style>