<template>
  <BaseModal
    name="ModalEditarSeller"
    id="ModalEditarSeller"
    size="xl"
    :title="
      seller_selected_card.name
        ? `Qual vendedor deseja atribuir para o lead ${seller_selected_card.name}?`
        : `Qual vendedor deseja atribuir para o lead?`
    "
  >
    <div class="container-sellers" v-if="sellers.length > 0" >
      <div v-for="(seller, index) in sellers" :key="index" class="cardsSellers">
        <div
          class="sectionSeller"
          :class="{
            sellerSelected: seller_selected_card.user_id == seller.id,
          }"
          @click="editVendedor(seller.id)"
        >
          <span class="selectSeller">Selecionar</span>
          <img
            class="user-icon2"
            v-if="filterSellerImg(seller) != 'no-photo'"
            :src="filterSellerImg(seller)"
            alt="seller"
          />
          <div class="seller-no-ft3">
            <h3>{{ filterSellerNoImg(seller) }}</h3>
          </div>

          <div class="dataSeller">
            <p>
              {{
                filterSellerName(seller).length > 25
                  ? filterSellerName(seller).substr(0, 24) + "..."
                  : filterSellerName(seller)
              }}
            </p>
            <span>
              {{
                seller.email.length > 25
                  ? seller.email.substr(0, 24) + "..."
                  : seller.email
              }}

              <!-- {{ seller.email }} -->
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="sellers.length === 0">
      <b-row class="Table-body justify-content-center">
        <p class="nao-encontrado">Nenhum vendedor foi encontrado</p>
      </b-row>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <!-- <BaseButton variant="primary" @click="onSubmit">Atribuir</BaseButton> -->
    </template>
  </BaseModal>
</template>
<script>
import SellerAutomation from "@/services/resources/SellerAutomation.js";
const serviceSellerAutomation = SellerAutomation.build();

import BaseButton from "@/components/BaseButton";

export default {
  components: {
    BaseButton,
  },
  props: ["seller_selected_card", "attr_list_seller", "sellers"],
  data() {
    return {
      seller_editar: [],
    };
  },
  computed: {},
  methods: {
    onSubmit(vendedor) {
      var data = {
        id: "assoc",
        leads: [this.seller_selected_card.id],
        user_id: vendedor,
      };
      serviceSellerAutomation
        .createId(data)
        .then((resp) => {
          this.$grToast.toast("Vendedor alterado com sucesso", {
            title: "Pipeline",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("ModalEditarSeller");
          if (this.attr_list_seller) {
            this.$parent.resetList();
          } else {
            this.$emit("update");
          }
          this.isVisible = false;
        })
        .catch(() => {
          this.$grToast.toast("Erro ao alterar vendedor", {
            title: "Pipeline",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    editVendedor(vendedor) {
      this.onSubmit(vendedor);
    },
    filterSellerName(seller) {
      if (seller.first_name) {
        return seller.first_name;
      }

      var regex = /@.*$/;
      if (regex.test(seller.email)) {
        var emailRegex = seller.email.replace(regex, "");
        return emailRegex;
      }

      return seller.email;
    },
    filterSellerNoImg(id) {
      if (id.first_name && id.last_name) {
        var name = id.first_name;

        var sobrenome = id.last_name;

        if (!name || !sobrenome) {
          return "";
        }

        return name[0] + sobrenome[0];
      }
      var email = id.email;

      if (!email) {
        return "";
      }

      return email[0] + email[1];
    },
    filterSellerImg(id) {
      if (id && id.metas) {
        for (let i = 0; i < id.metas.length; i++) {
          const element = id.metas[i];
          if (element.meta_key == "photo") {
            if (element.meta_value === "") {
              return "no-photo";
            } else if (typeof element.meta_value === "string") {
              return element.meta_value;
            } else {
              return "no-photo";
            }
          }
        }
      }
      return "no-photo";
    },
    filterName(seller) {
      var metas = seller.metas;

      for (let i = 0; i < metas.length; i++) {
        const meta = metas[i];
        if (meta.meta_key == "first_name") {
          if (typeof meta.meta_value === "string") {
            var name = meta.meta_value;
          }
        } else if (meta.meta_key == "last_name") {
          if (typeof meta.meta_value === "string") {
            var sobrenome = meta.meta_value;
          }
        }
      }
      if (!name || !sobrenome) {
        for (let i = 0; i < metas.length; i++) {
          const meta = metas[i];
          if (meta.meta_key == "nickname") {
            return meta.meta_value[0] + meta.meta_value[1];
          }
        }
      }
      return name + sobrenome;
    },
  },
};
</script>

<style scoped lang="scss">
.email {
  color: var(--gray01);
  font-weight: 600;
  font-size: 16px;
}
.select:hover {
  transform: scale(1.07);
}
.select {
  color: var(--bluee);
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 600;
  font-size: 16px;
}
.position {
  margin-top: 15px !important;
}
.name {
  color: var(--gray01);
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  line-height: 20px;
  padding-right: 8px;
  margin: 0;
  border-radius: 3px;
}
.Table-body:nth-child(2n + 1) {
  background: #fff;
}
.Table-body {
  padding: 0px !important;
  align-items: start !important;
}
.user-icon2 {
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 0px;
  cursor: pointer;
  z-index: 3;
  position: absolute;
  object-fit: cover;
}
.seller-no-ft3 {
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 0px;
  cursor: pointer;
  background: #81858e;
  z-index: 2;
}
.seller-no-ft3 h3 {
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  padding-top: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 2px;
}
/* .container-seller-info {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 10px;
} */
.container-sellers {
  display: grid;
  row-gap: 20px;
}
@media screen and (min-width: 1200px) {
  .container-sellers {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .container-sellers {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 991px) {
  .container-sellers {
    grid-template-columns: 1fr;
  }
  .cardsSellers{
    display: flex;
    justify-content: center;
  }
}
.sellerSelected {
  opacity: 0.5;
  transform: scale(0.99);
  user-select: none;
  pointer-events: none;
  .selectSeller {
    display: none !important;
  }
}
.sectionSeller {
  display: flex;
  gap: 15px;
  border: 1px solid #ededf0;
  border-radius: 10px;
  width: 340px;
  height: 85px;
  align-items: center;
  padding: 5px 10px;
  transition: all 0.3s;
  cursor: pointer;
  overflow: hidden;

  .selectSeller {
    transition: all 0.3s;
    top: -20px;
    position: absolute;
    right: 10px;
    font-size: 12px;
    font-weight: 500;
  }

  &:hover {
    .selectSeller {
      top: 10px;
    }
    transform: scale(0.99);
    background-color: #ededf057;
  }
  .dataSeller {
    display: flex;
    align-items: flex-start;
    gap: 3px;
    flex-direction: column;

    p {
      font-weight: 600;
      margin: 0px !important;
    }
  }
}
</style>
