import Cookies from "js-cookie";

export default{
  data() {
    return {
      hasLoaded: false,
      userLevelData: '',
      userData: {},
      tagsData: {},
      usersData: {},
      productsData: {},
      recursosData: {},
      blockClose: false,
      leadsUnification: false
    }
  },
  methods: {
    updateIframe(data){
      const dataKey = data.variable;
      this[dataKey] = data.newValue
      this.updateDataIframe(dataKey, data.newValue)
    },
    receiveMessage(messageEvent) {
      if (!messageEvent.data?.type || messageEvent.data.type == "webpackClose") {
        return
      }
      if (messageEvent.origin === window.location.origin) {
        this.$refs.iframeCRM?.updateCRM(messageEvent.data)
        return
      }
      this[messageEvent.data.type](messageEvent.data)
    },
    bearTokenAuth(data){
      if (Cookies.get('access_token') !== "Bearer " + data.auth) {
        Cookies.set("access_token", "Bearer " + data.auth, { expires: 365, sameSite: 'None', secure: true })
        this.returnPostMensage({type: 'reloadIframes'})
        return
      }
      Cookies.set("verify_email", data.verifyEmail, { expires: 365, sameSite: 'None', secure: true })
      Cookies.set("tenantSubdomain", data.subdomains, { expires: 365, sameSite: 'None', secure: true })
      this.userData = data.user
      this.userLevelData = data.user.level
      this.tagsData = data.tags
      this.usersData = data.users
      this.recursosData = data.resources
      this.productsData = data.products
      this.hasLoaded = true
    },
    clearAll() {
      Cookies.remove("access_token", { sameSite: 'None', secure: true });
      Cookies.remove("verify_email", { sameSite: 'None', secure: true });
      Cookies.remove("tenantSubdomain", { sameSite: 'None', secure: true });
    },
    returnPostMensage(array) {
      if (array.type === 'closeModal' && this.blockClose) {
        this.blockClose = false
        return
      } else if (array.type === 'closeModal' ) {
        this.reset()
      }
      window.parent.postMessage(array, 'https://web.whatsapp.com/');
    },
    setLeadsUnification(){
      this.$store.dispatch('getUniqueFlag', 'leads_unification')
        .then(async (resp) => {
          this.leadsUnification = resp;
        });
    },
  },
  mounted () {
    window.addEventListener('message', this.receiveMessage);
    this.setLeadsUnification();
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage)
  }
}
