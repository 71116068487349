<template>
  <div class="w-100" style="overflow-x: hidden;">
    <apexchart
      v-if="label.length && value.length"
      type="pie"
      height="300"
      :options="chartOptions"
      :series="value"
    />
    <div v-else style="font-size: 13px;">Associe produtos aos leads desse board para ver mais informações aqui</div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "chart",
  props: ["label", "value"],
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    chartOptions() {
      return {
        labels: this.label,
        colors: ['#2360A8', '#16C3B5', '#ff9e44', '#d31408'],
        chart: {
          height: 350,
          type: "pie",
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: 'bottom'
        },
      };
    },
  },
};
</script>